import { Component, OnDestroy, ViewChild } from '@angular/core';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as models from '../shared/models/model-names';
import { SyncProgressEvent } from '../shared/models/sync-progress-event';
import { EventService } from '../shared/services/event.service';
import { TrackerService } from '../shared/services/tracker.service';
import { XmasService } from '../shared/services/xmas.service';
import { BACKGROUND } from './backgrounds';
import { getMotto } from './mottos';
import { XMASBACKGROUND } from './xmasBackground';

@Component({
  selector: 'app-sync-indicator',
  templateUrl: './sync-indicator.page.html',
  styleUrls: ['./sync-indicator.page.scss'],
})
export class SyncIndicatorPage implements OnDestroy {
  @ViewChild('slider') slider: IonSlides;
  public progressItems: SyncProgressEvent[] = [];
  private _ngUnsubscribe = new Subject();
  public motto = {
    quoteText: 'Tue es, oder tue es nicht. Es gibt kein Versuchen.',
    author: 'Yoda',
  };
  public backgroundStyle = {};
  public background: string;
  public showIndicator = false;
  private backgrounds = BACKGROUND;

  constructor(
    private modalController: ModalController,
    public eventService: EventService<SyncProgressEvent>,
    private _platform: Platform,
    private _xmasService: XmasService,
    private _tracker: TrackerService
  ) {
    this.setRandomBackgroundImage();

    this.setMotto();

    // FIXME: this needs to go into ngOnInit()
    const modelNames = Object.keys(models);
    modelNames.forEach(modelName => {
      if (models[modelName] !== 'unknown' && models[modelName] !== 'synctimestamp' && models[modelName] !== 'logging') {
        this.progressItems.push(new SyncProgressEvent(models[modelName], 0, 0));
      }
    });

    eventService.events.pipe(takeUntil(this._ngUnsubscribe)).subscribe(progress => {
      if (!(progress instanceof SyncProgressEvent)) {
        return;
      }
      const progressItem = this.progressItems.find(f => f.title === progress.title);
      if (!progressItem) {
        this.progressItems.push(progress);
      } else {
        progressItem.progress = progress.progress;
        progressItem.total = progress.total;
      }
    });
  }

  ionViewDidEnter(): void {
    this.showIndicator = true;
    this._tracker.trackSyncOpen();
  }

  private setRandomBackgroundImage() {
    if (this._xmasService.isDuringXmasHolidays) {
      this.backgrounds = XMASBACKGROUND;
    }
    const rnd = Math.floor(Math.random() * this.backgrounds.length);
    const background = !this._platform.is('hybrid') ? this.backgrounds[rnd].desktop : this.backgrounds[rnd].mobile;
    this.backgroundStyle = { 'background-image': 'url(' + background + ')' };
  }

  private setMotto() {
    this.motto = getMotto(new Date().toISOString().substr(5, 5));
  }

  async goToDataPage() {
    this._tracker.trackSyncDetail();
    await this.slider.slideTo(1, 500);
  }

  async goToMottoPage() {
    await this.slider.slideTo(0, 500);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  public async dismiss() {
    this._tracker.trackSyncClose();
    await this.modalController.dismiss().catch(error => console.log(error));
  }
}
