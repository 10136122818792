import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Directive({
  selector: '[showWhen]',
})
export class ShowWhenDirective {
  private platform: Platforms;
  private _restBoolean = true;
  private _alreadyCreated = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _platform: Platform
  ) { }

  @Input()
  set showWhen(value: Platforms) {
    this.platform = value;
    this.updateView();
  }

  @Input()
  set showWhenNgIf(restBoolean: boolean) {
    this._restBoolean = restBoolean;
    this.updateView();
  }

  private updateView() {
    if (this.shouldShow() && this._restBoolean) {
      if (!this._alreadyCreated) {
        this._alreadyCreated = true;
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  private shouldShow() {
    if (this.platform.includes(',')) {
      const platforms = this.platform.split(',');
      return platforms.some(platform => this.isPlatform(platform));
    }
    return this.isPlatform();
  }

  private isPlatform(platform?) {
    return (platform || this.platform) === 'desktop' ? !this._platform.is('hybrid') : this._platform.is(this.platform);
  }
}
