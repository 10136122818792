import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import makeDebug from '../../../../makeDebug';
import { ReminderDB } from '../../../common/repository/databases';
import { DatabaseService } from '../../services/database.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { CommandQueueCleaner as CQCleaner } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:command-queue-cleaner');

@Injectable()
export class CommandQueueCleaner implements ISyncElement {
  public get name(): string {
    return CQCleaner;
  }

  constructor(private _databaseService: DatabaseService, private _platform: Platform) { }

  public async execute(_context: ISyncContext): Promise<void> {
    try {
      await this._platform.ready();
      if (this._platform.is('hybrid') === false) {
        return;
      }
      debug('calling commandQueue cleaner');

      const brokenCommandQueue = await this._databaseService.getDatabase(ReminderDB);
      const commandQueue = await this._databaseService.getDatabase('commandQueue.db');

      await brokenCommandQueue.create();
      await commandQueue.create();

      const commands = [];

      await brokenCommandQueue.forEach(reminderOrCommand => {
        if (reminderOrCommand?.entity) {
          commands.push(reminderOrCommand);
        }
      });

      for (const command of commands) {
        await commandQueue.set(command._id, command);
        await brokenCommandQueue.remove(command._id);
      }
    } catch (error) {
      console.error('error in commanqueucleaner', error);
    }
  }
}
