import { Injectable } from '@angular/core';
import { IConfig, IDeliveryInformationConfig, IPatientDataConfig } from '@pia/pia.shared';
import { ConfigDB } from 'src/app/common/repository/databases';
import { ConfigDto } from 'src/app/shared/models/config/config-dto.model';
import { QueryService } from 'src/app/shared/services/query/query.service';
import { configLinkTypes } from '../enum/configLinkTypes.enum';
import { configFeatureFlags } from '../enum/configFeatureFlags.enum';

@Injectable({ providedIn: 'root' })
export class TenantConfigService {
  constructor(private _queryService: QueryService) { }

  public async getTenantConfig(): Promise<IConfig | null> {
    const config = await this._queryService.getAll<ConfigDto>(ConfigDB);
    if (config && config[0]) {
      return config[0];
    } else {
      return null;
    }
  }

  public async showResmedTenant(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showResmedTenant);
  }

  public async showDevices(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showDevices);
  }

  public async showLimitedReport(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.disableReports);
  }

  public async getPatientDataConfig(): Promise<IPatientDataConfig | undefined> {
    const config = await this.getTenantConfig();
    return await config?.patientData;
  }

  public async hashAudits(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.hashAudit);
  }

  public async completeAuditsOnCreate(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.completeOnCreate);
  }

  public async getDeliveryInformation(): Promise<IDeliveryInformationConfig[]> {
    const config = await this.getTenantConfig();
    return config?.deliveryInformation;
  }

  public async showCrossTherapyArticleSearch(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return Boolean(config?.integratedCare?.showCrossTherapyArticleSearch);
  }

  public async getClassificationLabel(): Promise<string> {
    const config = await this.getTenantConfig();
    return config?.classificationLabel;
  }

  public async deliveryInformationRequired(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return Boolean(config?.deliveryInformationRequired);
  }

  public async getPatientClassifications(): Promise<string[]> {
    const config = await this.getTenantConfig();
    return config?.patientClassifications;
  }

  public async getMaxAnnotationLength(): Promise<number> {
    const config = await this.getTenantConfig();

    return config?.orderConfig.maxAnnotationLength;
  }

  public async getLink(type: configLinkTypes): Promise<string> {
    const config = await this.getTenantConfig();

    switch (type) {
      case configLinkTypes.HelpPage:
        return config?.links.helpPage;
      case configLinkTypes.PatchNotes:
        return config?.links.patchNotes;
      case configLinkTypes.PiAHelpDesk:
        return config?.links.piaHelpdesk;
      case configLinkTypes.Manual:
        return config?.links.manual;
      default:
        return '';
    }
  }

  public async isFeatureFlagSet(flag: configFeatureFlags) {
    const config = await this.getTenantConfig();
    if (!config.featureFlags) {
      return false;
    }
    switch (flag) {
      case configFeatureFlags.noContractSearch:
        return config?.featureFlags.noContractSearch;
      default:
        return false;
    }
  }
}
