import { Storage } from '@ionic/storage-angular';
import { openDB } from 'idb';
import { remove as _remove } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IIndexMetaDataInfo } from 'src/app/shared/services/contracts/database/index-metadata-info';
import { LogLevel } from '../contracts/logging/log-level';

enum SettledPromiseStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}
export class AlbertaStorage extends Storage implements IGenericStorage {
  private _storageConfig;

  constructor(config, public isDeletable: boolean, public canRepairIndex = true) {
    super(config);
    this._storageConfig = config;
  }

  public getItem(key: string, callback?: (error?: Error, result?: string) => void) {
    return this.get(key)
      .then(result => {
        if (callback) {
          callback(undefined, result);
        }
      })
      .catch(err => {
        if (callback) {
          callback(err);
        }
      });
  }

  public async getItems(keys: string[]): Promise<unknown[]> {
    const results = await Promise.allSettled(keys.map(key => this.get(key)));
    const fulfilledResults = [];
    results.forEach(result => {
      if (result.status === SettledPromiseStatus.Fulfilled) {
        if (result.value != null) {
          fulfilledResults.push(result.value);
        }
      } else if (result.status === SettledPromiseStatus.Rejected) {
        window.logger.log(`Failed to retrieve item from storage: ${result.reason}`, LogLevel.warning);
      } else {
        window.logger.log('Unexpected status of settled promise.', LogLevel.warning);
      }
    });
    return fulfilledResults;
  }

  public setItem(key, value, callback?: (error?: Error) => void) {
    return this.set(key, value).catch(err => {
      if (callback) {
        callback(err);
      }
    });
  }

  search(_query: string, _params?: any[]): Promise<any> {
    return;
  }

  executeBatch(_batch: any[]): Promise<any> {
    return;
  }
  repairIndex(): void {
    return;
  }

  readIndexFieldMetaInfo(_item): IIndexMetaDataInfo {
    return null;
  }

  public async setItems(itemsForDb: { items: any[]; deletable: boolean }): Promise<unknown> {
    const archived = _remove(itemsForDb.items, item => item.archived || (item.metadata && item.metadata.archived));
    if (archived && archived.length) {
      if (itemsForDb.deletable) {
        await Promise.all(archived.map(item => this.removeItem(item._id)));
      } else {
        itemsForDb.items.push(...archived);
      }
    }
    if (itemsForDb.items.length) {
      return Promise.all(itemsForDb.items.map(item => this.set(item._id, item)));
    }
  }

  public removeItem(key, callback?: (error?: Error) => void): Promise<any> {
    return this.remove(key).catch(err => {
      if (callback) {
        callback(err);
      }
    });
  }

  public removeItems(key): Promise<any> {
    this.getAllKeys((err, keys) => {
      if (err) {
        return;
      }
      keys.forEach(existingKey => {
        if (existingKey.includes(key)) {
          this.removeItem(existingKey);
        }
      });
    });
    return;
  }
  public getAll(): Observable<any> {
    const subject = new Subject();
    (async () => {
      await this.forEach(value => {
        subject.next(value);
      });
      subject.complete();
    })();

    return subject.asObservable();
  }

  public getAllKeys(callback?: (error?: Error, keys?: string[]) => void) {
    return openDB(this._storageConfig.name, 2)
      .then(db => db.getAllKeys(this._storageConfig.storeName))
      .then(keys => {
        if (callback) {
          callback(
            undefined,
            keys.map(key => key.toString())
          );
        }
      })
      .catch(error => {
        if (callback) {
          callback(error);
        }
      });
  }
}
