import { Therapy, TherapyType } from '@pia/pia.shared';

import { TherapyItem } from './therapy-item';
import { TherapyTypeItem } from './therapy-type';

export class Therapies {
  static therapyItems: TherapyItem[] = [
    new TherapyItem(Therapy.H_UNBEK, 'Unbekannt', 'H_UNBEK', 'alberta-general', '#3B507A', '#B4BED2', []),
    new TherapyItem(Therapy.H_STOMA, 'Entero-/Urostoma', 'H_STOMA', 'alberta-enterostomy', '#7700FF', '#D2ACFF', [
      new TherapyTypeItem(TherapyType.Colostoma_01, '01 Colostoma'),
      new TherapyTypeItem(TherapyType.Ileostoma_01, '01 Ileostoma'),
      new TherapyTypeItem(TherapyType.Urostoma_01, '01 Urostoma'),
    ]),
    new TherapyItem(Therapy.H_INKO, 'Harn-Inkontinenz', 'H_INKO', 'alberta-incontinence', '#00DFCB', '#C0FAF4', [
      new TherapyTypeItem(TherapyType.Aufsaugende_Versorgung_02, '02 aufsaugende Versorgung'),
      new TherapyTypeItem(TherapyType.Perkutane_Nephrostomie_02, '02 Perkutane Nephrostomie'),
      new TherapyTypeItem(
        TherapyType.Cystostomie_Punktionsfistel_Suprapubisch_02,
        '02 Cystostomie/Punktionsfistel, suprapubisch'
      ),
      new TherapyTypeItem(TherapyType.Transurethrale_Ableitung_02, '02 Transurethrale Ableitung'),
      new TherapyTypeItem(
        TherapyType.Ableitung_Pouch_katheterisierbar_02,
        '02 Ableitung über Pouch, katheterisierbar (MAINZ-Pouch, Mitrofanoff-Stoma)'
      ),
      new TherapyTypeItem(
        TherapyType.Intermitierender_Selbstkatheterismus_02,
        '02 Intermitierender Selbstkatheterismus, auch IFK'
      ),
      new TherapyTypeItem(TherapyType.Externe_Ableitung_02, '02 Externe Ableitung, Kondom'),
      new TherapyTypeItem(TherapyType.Therapie_sonstige_02, '02 Therapie, sonstige (Vaginaltampon etc.)'),
      new TherapyTypeItem(
        TherapyType.Externe_Ableitung_Urinalsystem_Bandage_02,
        '02 Externe Ableitung, Urinalsystem/-bandage'
      ),
    ]),
    new TherapyItem(Therapy.H_S_INKO, 'Stuhl-Inkontinenz ', 'H_S_INKO', 'alberta-incontinence', '#00DFCB', '#C0FAF4', [
      new TherapyTypeItem(TherapyType.Transanale_Irrigation_02, '02 Transanale Irrigation'),
      new TherapyTypeItem(TherapyType.Ableitung_Spuelung_Pouch_02, '02 Ableitung Spülung über Pouch'),
      new TherapyTypeItem(TherapyType.Auffang_Faekalkollektor_02, '02 Auffang mit Fäkalkollektor'),
      new TherapyTypeItem(TherapyType.Rueckhalt_Analtampon_02, '02 Rückhalt mit Analtampon'),
    ]),
    new TherapyItem(Therapy.H_WUND, 'Wunde, chronisch', 'H_WUND', 'alberta-wound', '#AE00A2', '#E1A6DF', [
      new TherapyTypeItem(TherapyType.Wunde_03, '03 Wunde'),
    ]),
    new TherapyItem(Therapy.H_EE, 'Enterale Ernährung ', 'H_EE', 'alberta-enteral-nutrition', '#FFD014', '#FFEEAE', [
      new TherapyTypeItem(TherapyType.Schwerkraftversorgung_04, '04 Schwerkraftversorgung'),
      new TherapyTypeItem(TherapyType.Pumpenversorgung_04, '04 Pumpenversorgung'),
      new TherapyTypeItem(
        TherapyType.Versorgung_Bolusgabe_Button_Gastrotube,
        '04 Versorgung mittels Bolusgabe/Button-Gastrotube'
      ),
      new TherapyTypeItem(TherapyType.Trinknahrung_04, '04 Trinknahrung'),
      new TherapyTypeItem(TherapyType.Absaugung_04, '04 Absaugung'),
      new TherapyTypeItem(TherapyType.Bolusgabe_04, '04 Bolusgabe'),
    ]),
    new TherapyItem(Therapy.H_PE, 'Parenterale Ernährung ', 'H_PE', 'alberta-enteral-nutrition', '#FFD014', '#FFEEAE', [
      new TherapyTypeItem(TherapyType.Schwerkraftversorgung_05, '05 Schwerkraftversorgung'),
      new TherapyTypeItem(TherapyType.Pumpenversorgung_05, '05 Pumpenversorgung'),
    ]),
    new TherapyItem(Therapy.H_TRACHERO, 'Tracheostoma', 'H_TRACHERO', 'alberta-tracheostomy', '#0080FF', '#A6D2FF', [
      new TherapyTypeItem(TherapyType.Tracheostoma_beatmet_06, '06 Tracheostoma, beatmet'),
      new TherapyTypeItem(TherapyType.Tracheostoma_unbeatmet_06, '06 Tracheostoma, unbeatmet'),
      new TherapyTypeItem(TherapyType.Larynektomie_06, '06 Laryngektomie '),
      new TherapyTypeItem(TherapyType.Absaugung_06, '06 Absaugung'),
    ]),
    new TherapyItem(
      Therapy.H_SCHMERZ,
      'Schmerz- & Antibiose-Therapie',
      'H_SCHMERZ',
      'alberta-pain-therapy',
      '#9E0059',
      '#E7BFD5',
      [
        new TherapyTypeItem(TherapyType.Schmerztherapie_08, '08 Schmerztherapie'),
        new TherapyTypeItem(TherapyType.Antibiose_Therapie_08, '08 Antibiose-Therapie'),
      ]
    ),
    new TherapyItem(Therapy.H_FISTEL, 'Wundfistel', 'H_FISTEL', 'alberta-wound', '#AE00A2', '#E1A6DF', [
      new TherapyTypeItem(TherapyType.Wundfistel_09, '09 Wundfistel'),
    ]),
    new TherapyItem(
      Therapy.H_SONDER,
      'Spezialversorgung/Sonderform',
      'H_SONDER',
      'alberta-rehab-aids',
      '#AE00A2',
      '#F2BDF0',
      [
        new TherapyTypeItem(TherapyType.Freiverkauf_Reiner_99, '99 Freiverkauf, reiner'),
        new TherapyTypeItem(TherapyType.Werbemittel_99, '99 Werbemittel'),
        new TherapyTypeItem(TherapyType.Umlagerung_Autolager_AD_99, '99 Umlagerung Autolager AD'),
        new TherapyTypeItem(TherapyType.Muster_99, '99 Muster'),
        new TherapyTypeItem(TherapyType.Eigenbedarf_99, '99 Eigenbedarf'),
        new TherapyTypeItem(TherapyType.Grosshandel_94, '94 Großhandel'),
        new TherapyTypeItem(TherapyType.Pharm_Grosshandel_05, '05 pharm. Großhandel'),
        new TherapyTypeItem(TherapyType.Sonstige_Anwendung_95, '95 Sonstige Anwendung'),
        new TherapyTypeItem(TherapyType.Service_97, '97 Service'),
      ]
    ),
    new TherapyItem(Therapy.H_APO, 'Apothekenprozess HARSUM', 'H_APO', 'alberta-rehab-aids', '#AE00A2', '#F2BDF0', [
      new TherapyTypeItem(TherapyType.GH_Grosshandel, 'GH Großhandel'),
    ]),
  ];

  static getTherapy(id: number): TherapyItem {
    const therapyItem = this.therapyItems.find(therapy => therapy.id === id);

    return therapyItem ? therapyItem : Therapies.therapyItems[0];
  }

  static getTherapyByAbbreviation(abbreviation: string) {
    const therapyItem = this.therapyItems.find(therapy => therapy.abbreviation === abbreviation);
    return therapyItem ? therapyItem : Therapies.therapyItems[0];
  }

  static getTherapyType(therapyId: number, therapyTypeId: number): TherapyTypeItem {
    const therapyItem: TherapyItem = this.therapyItems.find(therapy => therapy.id === therapyId);
    if (!therapyItem) {
      return;
    }
    return therapyItem.therapyTypes.find(therapyTypeItem => therapyTypeItem.id === therapyTypeId);
  }

  static getTherapyTypeByName(abbreviation: string, name: string) {
    const therapyItem = this.getTherapyByAbbreviation(abbreviation);
    if (!therapyItem) {
      return;
    }
    return therapyItem.therapyTypes.find(therapyTypeItem => therapyTypeItem.id === TherapyType[name]);
  }

  static getTherapyAndTypeName(therapyId: number, therapyTypeId: number): string {
    const therapyItem: TherapyItem = this.therapyItems.find(therapy => therapy.id === therapyId);
    if (!therapyItem) {
      return;
    }
    const therapyType = therapyItem.therapyTypes.find(therapyTypeItem => therapyTypeItem.id === therapyTypeId);
    if (!therapyType) {
      return therapyItem.displayName;
    }
    return `${therapyItem.displayName} (${therapyType.displayName})`;
  }

  static getNextVisitDate(id: number): Date {
    const nextVisitDate = new Date();

    switch (id) {
      case 2:
        nextVisitDate.setDate(nextVisitDate.getDate() + 14);
        break;
      case 6:
        nextVisitDate.setDate(nextVisitDate.getDate() + 7);
        break;
      case 3:
        nextVisitDate.setMonth(3);
        break;
      default:
        nextVisitDate.setDate(nextVisitDate.getDate() + 28);
        break;
    }

    return nextVisitDate;
  }

  static getDueColor(date: Date): string {
    // TODO: differentiate by therapy
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() - 7);
    if (new Date(date) < dueDate) {
      return '#FF6E6E';
    }

    return '#127C77';
  }
}
