import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Directory, Filesystem, RmdirOptions } from '@capacitor/filesystem';
import {
  CodePush,
  DownloadProgress,
  ILocalPackage,
  InstallMode,
  SuccessCallback,
  SyncOptions,
} from '@ionic-native/code-push/ngx';
import { Platform } from '@ionic/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface IDeploymentKey {
  deploymentKey: string;
  platform: string;
  deployment: string;
}

export const DEPLOYMENTKEYS = new InjectionToken<IDeploymentKey[]>('deployment keys', {
  providedIn: 'root',
  factory: () => environment.codePushDeploymentKeys,
});

export const DEFAULTSYNCOPTIONS = new InjectionToken<SyncOptions>('default sync options', {
  providedIn: 'root',
  factory: () => ({
    updateDialog: false,
    installMode: InstallMode.ON_NEXT_RESTART,
  }),
});

@Injectable({ providedIn: 'root' })
export class CodePushService {
  public currentPackage: ILocalPackage;
  public currentDeployment: string;

  constructor(
    private _codePush: CodePush,
    private _platform: Platform,
    @Inject(DEPLOYMENTKEYS) private _deploymentKeys: IDeploymentKey[],
    @Inject(DEFAULTSYNCOPTIONS) private _defaultSyncOptions: SyncOptions
  ) { }

  public async sync() {
    if (!this._platform.is('hybrid') || environment.disableDeploy) {
      return;
    }
    await this._platform.ready();
    this.currentPackage = await this._codePush.getCurrentPackage();
    let syncOptions: SyncOptions = { ...this._defaultSyncOptions };
    if (this.currentPackage && this.currentPackage.deploymentKey) {
      const deploymentKey = this._deploymentKeys.find(f => f.deploymentKey === this.currentPackage.deploymentKey);
      if (deploymentKey) {
        this.currentDeployment = deploymentKey.deployment;
      }
      syncOptions = { ...syncOptions, deploymentKey: this.currentPackage.deploymentKey };
    }
    window.logger.log('checking for updates');
    return this._codePush.sync(syncOptions);
  }

  public changeDeployment(newDeployment: string, downloadProgress?: SuccessCallback<DownloadProgress>) {
    if (!newDeployment || !(newDeployment === 'dev' || newDeployment === 'staging' || newDeployment === 'production')) {
      return;
    }
    let currentPlatform: 'ios' | 'android';
    if (this._platform.is('ios')) {
      currentPlatform = 'ios';
    }
    if (this._platform.is('android')) {
      currentPlatform = 'android';
    }
    const keyFinder = (key: IDeploymentKey) => key.deployment === newDeployment && key.platform === currentPlatform;
    const deploymentKey = this._deploymentKeys.find(keyFinder);
    if (!deploymentKey || !deploymentKey.deploymentKey) {
      return;
    }
    console.log('newDeployment', newDeployment);
    console.log('deploymentKey', deploymentKey);

    const syncOptions: SyncOptions = {
      ...this._defaultSyncOptions,
      deploymentKey: deploymentKey.deploymentKey,
      mandatoryInstallMode: InstallMode.ON_NEXT_RESTART,
      updateDialog: false,
      minimumBackgroundDuration: 0,
    };

    if (this.currentPackage) {
      return from(this.removeCodePushFiles()).pipe(switchMap(() => this._codePush.sync(syncOptions, downloadProgress)));
    } else {
      return this._codePush.sync(syncOptions, downloadProgress);
    }
  }

  public restartApplication() {
    return this._codePush.restartApplication();
  }

  private async removeCodePushFiles() {
    try {
      console.log('removing local package');

      // Code-Push ist ein Cordova-Plugin und funktioniert eh nicht...
      const options: RmdirOptions = {
        path: 'codepush',
        directory: Directory.Data, // Wo ist das codepush Directory? Wo wird es gespeichert?
        recursive: true
      }
      await Filesystem.rmdir(options);
      // const removeResult = await this._file.removeRecursively(this._file.dataDirectory, 'codepush');
      // if (!removeResult.success) {
      //   console.error('removeResult', removeResult);
      // }
    } catch (e) {
      console.error('removeError', e);
    }
  }
}
