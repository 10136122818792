import { inject, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import pouchdbDebug from 'pouchdb-debug';
import { RxDatabase, createRxDatabase, addRxPlugin, RxJsonSchema } from 'rxdb';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { takeWhile } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';

import { AuthService } from '../../auth.service';
import { Branch, EnvironmentService } from '../../environment/environment.service';
import {
  channelsMigrationStrategies,
  channelsSchema,
  ChatChannel,
  chatChannelConsumptionSchema,
  ChatChannelConsumptionStatus,
  ChatCollections,
  ChatMember,
  ChatMessage,
  membersSchema,
  messagesMigrationStrategies,
  messagesSchema,
} from './db-schema';

import pouchdbAdapterIdb from 'pouchdb-adapter-idb';
import * as watchForChanges from 'rxdb/plugins/watch-for-changes';
import pouchDbAdapterCordova from 'pouchdb-adapter-cordova-sqlite';

const debug = makeDebug('services:chat:db');

export const RX_DB_SERVICE_TOKEN = new InjectionToken<Promise<RxDatabase<ChatCollections>>>(
  'Manually constructed MyService',
  {
    providedIn: 'root',
    factory: () => _initDb(inject(Platform), inject(AuthService), inject(EnvironmentService)),
  }
);

// // abstraction of static class with depdency injection
// export const RX_DB_TOKEN = new InjectionToken<typeof RxDB>('rxdb token', {
//   providedIn: 'root',
//   factory: () => RxDB,
// });

export async function _initDb(platform: Platform, authService: AuthService, environmentService: EnvironmentService) {
  addRxPlugin(pouchdbAdapterIdb);
  addRxPlugin(pouchdbDebug);
  addRxPlugin(watchForChanges);
  addRxPlugin(RxDBMigrationPlugin);

  debug('init db');
  const isCordova = platform.is('hybrid');
  const pouchSettings: any = {
    revs_limit: 1,
  };

  if (isCordova) {
    debug('Use cordova');
    addRxPlugin(pouchDbAdapterCordova);
    pouchSettings.iosDatabaseLocation = 'default';
    pouchSettings.androidDatabaseImplementation = 2;
  }
  // migration - see rx-db-migration.spec.ts (to migrate schema)
  // https://github.com/pubkey/rxdb/blob/master/docs-src/data-migration.md
  // Use for dev
  // await RxDB.removeDatabase('chatdb', 'idb');

  const db = await createRxDatabase<ChatCollections>({
    name: await createDbName(authService, environmentService),
    adapter: isCordova ? 'cordova-sqlite' : 'idb',
    ignoreDuplicate: true,
    eventReduce: true,
    pouchSettings,
  });
  const setupPromises = [
    db.addCollections({
      channels: {
        schema: channelsSchema as RxJsonSchema<ChatChannel>,
        migrationStrategies: channelsMigrationStrategies,
      },
      consumptions: {
        schema: chatChannelConsumptionSchema as RxJsonSchema<ChatChannelConsumptionStatus>,
      },
      messages: {
        schema: messagesSchema as RxJsonSchema<ChatMessage>,
        migrationStrategies: messagesMigrationStrategies,
      },
      members: {
        schema: membersSchema as RxJsonSchema<ChatMember>,
      },
    }),
  ];
  await Promise.all(setupPromises);
  debug('db init completed');
  // setup watch for changes when writing directly with .pouch
  db.messages.watchForChanges();
  return db;
}

export function createDbName(authService: AuthService, environmentService: EnvironmentService) {
  return new Promise<string>(resolve => {
    authService.authenticatedEventPublisher.pipe(takeWhile(authEventData => !authEventData.isAuthenticated)).subscribe({
      complete: () => {
        const account = authService.authentication.account;
        const branch = environmentService.branch === Branch.production ? 'master' : environmentService.branch;
        const dbName = `${account.firstName[0]}${account.lastName[0]}${account._id.slice(-4)}_${branch}`;

        resolve(dbName.toLowerCase());
      },
    });
  });
}
