import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { AuthActions, AuthObserver, AuthService as MobileOAuthService, IAuthAction } from 'ionic-appauth';
import { clamp } from 'lodash';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { IAuthEventData } from 'src/app/common/contracts/authentication/auth-event-data';
import { MenuItem } from 'src/app/common/menu/menu-item';
import { Branch, EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { AuthService } from '../../services/auth.service';
import { ChatMessagesStatusService } from '../../services/chat/chat-messages-status.service';
import { AttachmentNotificationFilterService } from '../../services/notification-filter/attachment-notification-filter-service';
import { XmasService } from '../../services/xmas.service';
import { MainMenu } from './main-menu';
import { CheckAppVersionService } from '../../services/check-app-version/check-app-version.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChatConnectionStateService } from '../../services/chat/chat-connection-state.service';
import { CommandQueue } from 'src/app/commands/command-queue';

@Component({
  selector: 'itl-split-pane',
  templateUrl: './split-pane.component.html',
  styleUrls: ['./split-pane.component.scss'],
})
export class SplitPaneComponent implements OnInit {
  public menuItems: MenuItem[];
  public isMasterBranch: boolean;
  public branchName: string;
  public version: string;
  public isAppVersionOutdated$: BehaviorSubject<boolean>;
  public showNewNotifications = false;
  public isMenuExpanded = false;
  public selectedCategory = -1;
  public userIsAuthenticated = false;
  public unreadMessages = 0;
  public unreadAgentMessages = 0;
  public numberOfNewNotifications = 0;
  public userIsChatAgent = false;
  public online = true;
  public unsendData: number = undefined;
  public commandError: Error = undefined;
  public isLoggingOut = false;
  public isDuringXmasHolidays = false;

  private _oauthObserver: AuthObserver;
  private _ngDestroy = new Subject();

  constructor(
    public authService: AuthService,
    private router: Router,
    private menu: MenuController,
    private _platform: Platform,
    private _xmaxService: XmasService,
    private _attachmentNotificationFilterService: AttachmentNotificationFilterService,
    private readonly _chatMessagesStatusService: ChatMessagesStatusService,
    private _environmentService: EnvironmentService,
    private checkAppVersionService: CheckAppVersionService,
    private _mobileOAuthService: MobileOAuthService,
    private _toastController: ToastController,
    private _changeDetectorRef: ChangeDetectorRef,
    private _connectionStateService: ChatConnectionStateService,
    private _commandQueue: CommandQueue
  ) {
    this._environmentService.ready
      .then(() => {
        console.log('branch', this._environmentService.branch);
        this.isMasterBranch = this._environmentService.branch === Branch.production;
        this.branchName = this._environmentService.branch;
        this.version = this._environmentService.version;

        this.observeUnsendData();
        this.observeCommandQueueErrors();
        this.observeOnlineState();
      })
      .catch(err => console.error(err));
  }

  async ngOnInit() {
    await this._platform.ready();
    this.authService.authenticatedEventPublisher
      .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
      .subscribe({
        complete: () => {
          this.userIsAuthenticated = true;
          this.userIsChatAgent = Boolean(this.authService.authentication?.account?.isAgent);
          this.menuItems = MainMenu.get(!this._platform.is('hybrid'), this.authService);
        },
      });
    this.setNotificationDisturber();
    this._chatMessagesStatusService.getTotalUnreadMessagesCount().subscribe(unreadMessages => {
      this.unreadMessages = clamp(unreadMessages, 0, 999);
    });
    this._chatMessagesStatusService.getTotalUnreadAgentMessagesCount().subscribe(unreadMessages => {
      this.unreadAgentMessages = clamp(unreadMessages, 0, 999);
    });

    this.isDuringXmasHolidays = this._xmaxService.isDuringXmasHolidays;
    this.isAppVersionOutdated$ = this.checkAppVersionService.isAppVersionOutdated$;
  }

  private observeUnsendData() {
    this._commandQueue.count.pipe(takeUntil(this._ngDestroy)).subscribe(unsendData => {
      this.unsendData = unsendData;
      this._changeDetectorRef.detectChanges();
    });
  }

  private observeCommandQueueErrors() {
    this._commandQueue.currentError$.pipe(takeUntil(this._ngDestroy)).subscribe(error => {
      this.commandError = error;
      this._changeDetectorRef.detectChanges();
    });
  }

  public isDuplicateError(error: Error) {
    return error && error.message.contains('Patient Duplikatprüfung fehlgeschlagen');
  }

  public deleteDuplicate() {
    this._commandQueue.deleteDuplicatesFromQueue();
  }

  private observeOnlineState() {
    this._connectionStateService
      .observeOnlineState()
      .pipe(takeUntil(this._ngDestroy))
      .subscribe(isOnline => {
        this.online = isOnline;
        this._changeDetectorRef.detectChanges();
      });
  }

  public onOpen() { }

  public onClose() {
    this.isMenuExpanded = false;
    this.selectedCategory = -1;
  }

  public async openPage(url: string) {
    this.onClose();
    if (url === '/formulare') {
      window.open(this._environmentService.swodocUrl, '_blank');
      await this.menu.close('mainNav');
      return;
    }

    await this.menu.close('mainNav');
    if (url) {
      await this.router.navigateByUrl(url);
    }
    if (url === '/notifications') {
      this.showNewNotifications = false;
    }
  }

  public async logout() {
    if (this.isLoggingOut) {
      return;
    }

    this.isLoggingOut = true;
    this.onClose();

    if (this._platform.is('hybrid')) {
      const logoutToast = await this._toastController.create({
        message: 'Du wirst abgemeldet...',
        position: 'bottom',
        buttons: [
          {
            text: 'Schließen',
            role: 'cancel',
          },
        ],
      });
      await logoutToast.present();
      this._oauthObserver = this._mobileOAuthService.addActionListener(action => this.onSignOutAction(action));
    }
    await this.authService.logout();
  }

  private setNotificationDisturber() {
    this.authService.authenticatedEventPublisher.subscribe((authEvent: IAuthEventData) => {
      if (!authEvent.isAuthenticated) {
        return;
      }

      this._attachmentNotificationFilterService.filter().subscribe(attachments => {
        const attachmentNotHasBeenSeen = attachments.filter(attachment => !attachment.hasBeenSeen);
        this.showNewNotifications = attachmentNotHasBeenSeen.length > 0;
        this.numberOfNewNotifications = attachmentNotHasBeenSeen.length;
      });
    });
  }

  private async onSignOutAction(action: IAuthAction) {
    this._mobileOAuthService.removeActionObserver(this._oauthObserver);

    if (action.action === AuthActions.SignOutSuccess) {
      window.location.reload();
    }
    if (action.action === AuthActions.SignOutFailed) {
      window.logger.log(`sing out failed - error: ${action.error}`);
      const toast = await this._toastController.create({
        message: action.error,
        duration: 10000,
        position: 'top',
        cssClass: 'login-toast',
      });
      await toast.present();
    }
  }

  goToHomepage() {
    this.router.navigate(['dashboard']);
  }
}
