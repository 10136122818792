import { Platform } from '@ionic/angular';
import { MbscCalendarOptions } from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

export function getMobiScrollCalenderOptions(
  translate: TranslateService,
  plt: Platform,
  min?: Date,
  max?: Date
): MbscCalendarOptions {
  const options: MbscCalendarOptions = {
    select: 'single',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    setText: 'OK',
    theme: 'alberta',
    dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
    monthNamesShort: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    months: 1,
  };
  options.cancelText = translate.instant('CANCEL_BUTTON') as any;
  if (!plt.is('hybrid')) {
    options.months = 3;
  }
  if (min) {
    options.min = min;
  }
  if (max) {
    options.max = max;
  }
  return options;
}

const TIMEPICKERALBERTATHEME: NgxMaterialTimepickerTheme = {
  container: {
    buttonColor: getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-secondary-variant'),
  },
  dial: {
    dialBackgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-primary-variant'),
  },
  clockFace: {
    clockHandColor: getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-secondary-variant'),
  },
};
export default TIMEPICKERALBERTATHEME;
