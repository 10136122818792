import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import de from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import {
  MsalModule,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalService,
} from '@azure/msal-angular';
import { CodePush } from '@ionic-native/code-push/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MbscModule } from '@mobiscroll/angular';
import * as Sentry from '@sentry/angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import moment from 'moment';
import { MobileOAuthModule } from 'src/app/auth/mobile-oauth.module';
import { environment } from 'src/environments/environment';
import { AppInitService } from './app-init.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { WorkItemModule } from './business/work-item.module';
import { CommandModule } from './commands/command.module';
import authentication from './common/authentication/authentication-account';
import { AuthenticationToken } from './common/contracts/authentication/authentication-account';
import { PlatformSyncToken } from './common/contracts/sync/platform-sync';
import platformSync from './common/sync/platform-sync';
import { SentryIonicErrorHandler } from './error-handler';
import { FaxCreateModalModule } from './fax/components/fax-create/fax-create.module';
import { I18nModule } from './i18n/i18n.module';
import { LightboxModule } from './shared/components/common/lightbox/lightbox.module';
import { MaintenanceInfoModalModule } from './shared/components/maintenance/maintenance-info-modal/maintenance-info-modal.module';
import { MaintenanceSnackBarModule } from './shared/components/maintenance/maintenance-snack-bar/maintenance-snack-bar.module';
import { SplitPaneModule } from './shared/components/split-pane/split-pane.module';
import { ProviderToken } from './shared/services/contracts/general-entity.service';
import { FieldNurseService } from './shared/services/field-nurse.service';
import { GeneralEntityService } from './shared/services/general-entity.service';
import { InstitutionContactFunctionService } from './shared/services/institution-contact-function.service';
import providerConfig, { ProviderConfigToken } from './shared/services/provider-config';
import { SharedModule } from './shared/shared.module';
import { SyncIndicatorPageModule } from './sync-indicator/sync-indicator.module';
import { AlbertaCommonComponentsModule } from './shared/components/common/common-components.module';
import { createMsalApp, createMsalGuardConfig, createMsalInterceptorConfig } from './auth/msal';

moment.updateLocale('de', {});
registerLocaleData(de, 'de-DE');

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new (<any>window).Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]],
    });
  }
}

export function initApp(appInitService: AppInitService) {
  return (): void => appInitService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlbertaCommonComponentsModule,
    FormsModule,
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollAssist: false,
      animated: true,
      rippleEffect: false,
      hardwareBackButton: false,
    }),
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    I18nModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AppRoutingModule,
    SharedModule,
    CommandModule.forRoot(),
    WorkItemModule.forRoot(),
    HttpClientModule,
    SplitPaneModule,
    LightboxModule,
    SyncIndicatorPageModule,
    FaxCreateModalModule,
    MaintenanceSnackBarModule,
    MaintenanceInfoModalModule,
    HammerModule,
    MsalModule,
    OAuthModule.forRoot(),
    MobileOAuthModule,
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService], multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    SQLite,
    Geolocation,
    CodePush,
    { provide: OAuthStorage, useValue: localStorage },
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // TODO: remove Token
    { provide: ProviderConfigToken, useValue: providerConfig },
    // TODO: remove Token
    { provide: ProviderToken, useClass: GeneralEntityService, multi: true },
    // TODO: remove Token
    { provide: ProviderToken, useClass: FieldNurseService, multi: true },
    { provide: ProviderToken, useClass: InstitutionContactFunctionService, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: AuthenticationToken, useValue: authentication },
    { provide: PlatformSyncToken, useValue: platformSync },
    { provide: MSAL_INSTANCE, useFactory: createMsalApp },
    { provide: MSAL_GUARD_CONFIG, useFactory: createMsalGuardConfig },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: createMsalInterceptorConfig },
    MsalBroadcastService,
    MsalService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
